.poll-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: white;
    height: 100%;
    --accent-color: rgb(0, 174, 158);
    --voter-img-size: 100px;

    .poll-voters {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 1rem;

        >div {
            padding: .5rem;
            font-size: 40px;
            font-weight: 400;
            line-height: 61px;
            letter-spacing: 0em;
            text-align: left;
        }
    }

    .poll-results {
        padding: 0 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;

        .poll-result-row {
            >td {
                height: 200px;
            }

            .poll-result-options {
                white-space: nowrap;
                width: 1%;

                .poll-result-option-container {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;

                    .poll-result-option-name {
                        font-size: 71px;
                        font-weight: 600;
                        line-height: 108px;
                        letter-spacing: 0em;
                        text-align: left;
                    }

                    .poll-result-option-value {
                        margin: 0 1rem;
                        font-size: 71px;
                        font-weight: 600;
                        line-height: 108px;
                        letter-spacing: 0em;
                        text-align: left;
                    }
                }

                .poll-result-option-bar {
                    position: relative;
                    height: 1rem;
                    transition: width 2s;
                }
            }
        }
    }
}