.poll-running {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 1rem;

    --poll-icon-size: 85px;
    --poll-spinner-filter: brightness(0) saturate(100%) invert(69%) sepia(85%) saturate(4587%) hue-rotate(139deg) brightness(90%) contrast(101%);
    --accent-color: rgb(0, 174, 158);

    .poll-icon-container {
        aspect-ratio: 1;
        position: relative;
        height: var(--poll-icon-size);
        width: var(--poll-icon-size);

        .spinner {
            position: absolute;
            filter: var(--poll-spinner-filter);
            transform: scale(1.1);
        }

        .avatar-container {
            padding: .375rem;

            .avatar {
                border-radius: 50%;
                background-color: var(--accent-color);
                padding: .5rem;
            }
        }
    }

    .poll-result-container {
        flex-grow: 1;
        margin: 0px 1rem;

        .poll-result-text-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            color: white;
            align-items: flex-end;
            margin-bottom: 0.3rem;

            >h2 {
                margin: 0px;
                font-size: 44px;
                font-weight: 600;
                line-height: 67px;
                letter-spacing: 0em;
                text-align: left;
            }

            >span {
                font-size: 40px;
                font-weight: 400;
                line-height: 61px;
                letter-spacing: 0em;
                text-align: left;
            }
        }

        .poll-result-bar-container {
            position: relative;
            background-color: black;
            height: 1rem;
            border-radius: 1rem;

            >div {
                background-color: var(--accent-color);
                height: inherit;
                border-radius: inherit;
                transition: width 2s;
            }
        }
    }
}