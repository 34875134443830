.intervention {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    --enqueued-img-size: 83px;

    --speaking-img-size: 85px;
    --speaking-img-spinner-filter: brightness(0) saturate(100%) invert(69%) sepia(85%) saturate(4587%) hue-rotate(139deg) brightness(90%) contrast(101%);

    .enqueued-img-wrapper {
        position: relative;
        height: var(--enqueued-img-size);
        width: var(--enqueued-img-size);
        overflow: visible;
    }

    .enqueued-img-wrapper>img {
        position: absolute;
        top: 0;
        left: 23px;
        margin: auto 0;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        opacity: .6;
    }


    .speaking-participant-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 1rem;
        width: 100%;

        .speaking-participant-name-wrapper {
            color: white;
            margin-left: 1rem;
            
            >h3 {
                margin: 0;
                font-size: 44px;
                font-weight: 600;
                line-height: 67px;
                letter-spacing: 0em;
                text-align: left;
            }

            >span {
                font-size: 29px;
                font-weight: 400;
                line-height: 44px;
                letter-spacing: 0em;
                text-align: left;

            }
        }

        .speaking-participant-img-wrapper {
            aspect-ratio: 1;
            position: relative;
            height: var(--speaking-img-size);
            width: var(--speaking-img-size);

            .spinner {
                position: absolute;
                transform: scale(1.2);
                filter: var(--speaking-img-spinner-filter);
            }

            .avatar {
                height: 100%;
                width: 100%;
                border-radius: 50%;
            }
        }
    }
}