.agenda-item-container {
    margin-left: 1rem;
    display: flex;
    flex-direction: row;

    --bubble-dimension: 60px;
    --speaking-img-spinner-filter: brightness(0) saturate(100%) invert(69%) sepia(85%) saturate(4587%) hue-rotate(139deg) brightness(90%) contrast(101%);


    .agenda-item-bubbles-container {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        margin: 0;
        padding: 0;

        .agenda-item-bubble-wrapper {
            aspect-ratio: 1;
            position: relative;
            height: var(--bubble-dimension);
            width: var(--bubble-dimension);
            margin-right: -1rem;
            border-radius: 50%;
            border: 6px solid black;

            .spinner {
                position: absolute;
                transform: scale(1.2);
                filter: var(--speaking-img-spinner-filter);
            }

            .agenda-item-bubble {
                z-index: 1;
                height: 100%;
                width: 100%;
                border-radius: 50%;
                background-color: rgb(0, 174, 158);
                display: flex;
                align-items: center;
                justify-content: center;

                >h2 {
                    font-size: 33px;
                    font-weight: 600;
                    line-height: 50px;
                    letter-spacing: 0em;
                    text-align: left;
                }
            }
        }
    }

    .agenda-item-title-container {
        margin-left: 2rem;
        color: white;
        width: calc((100% - var(--bubble-dimension)) - 2rem);

        .agenda-item-description {
            margin: 0;
            font-size: 39px;
            font-weight: 400;
            line-height: 59px;
            letter-spacing: 0em;
            text-align: left;
        }
    }
}