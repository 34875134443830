.caption-page-wrapper {

  .caption-page {
    background-color: transparent;
    background-image:
      linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0.7) 160px, rgba(0, 0, 0, 0) 316px),
      linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.7) 80px, rgba(0, 0, 0, 0) 157px);

  }

  .caption-page.poll {
    background-image: none;
    background-color: rgba(0, 0, 0, 0.85);
  }



  /* .caption-page::before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    background-image:
      linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0.7) 160px, rgba(0, 0, 0, 0) 316px),
      linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.7) 80px, rgba(0, 0, 0, 0) 157px);
  }

  .caption-page::after {
    position: absolute;
    content: '';
    top: 100%;
    height: 100%;
    width: 100%;
    background-color: black;
  }

  .caption-page.poll::before {
    top: -100%;
    animation: translate-before 2s ease;
  }

  .caption-page.poll::after {
    top: 0;
    opacity: .7;
    animation: translate-after 2s ease;
  } */

  .caption-page {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .top,
    .center,
    .bottom {
      z-index: 1;
      overflow: hidden;
    }

    .top {
      width: 100%;
      height: 175px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .content {
        flex-grow: 1;
        max-width: calc(100% - 75px)
      }

      .logo {
        width: 75px;
        z-index: 1;
      }
    }

    .center {
      height: 70%;
    }

    .bottom {
      height: 316px;
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
    }
  }
}

@keyframes translate-before {
  0% {
    top: 0;
    opacity: 1;
  }

  100% {
    top: -100%;
    opacity: .7;
  }
}

@keyframes translate-after {
  0% {
    top: 100%;
    opacity: 1;
  }

  100% {
    top: 0;
    opacity: .7;
  }
}