.voters-container {
    display: flex;
    flex-direction: row;

    .voter-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 200px;

        .voter-name-wrapper {
            color: white;
            max-width: 100%;

            >h3 {
                margin: 0;
                font-size: 22px;
                font-weight: 400;
                line-height: 33px;
                letter-spacing: 0em;
                text-align: center;
                white-space: normal;
                word-wrap: break-word;

            }
        }

        .voter-img-wrapper {
            aspect-ratio: 1;
            position: relative;
            height: var(--voter-img-size);
            width: var(--voter-img-size);
            border: .2rem solid;
            padding: .4rem;
            border-radius: 50%;

            .avatar {
                height: 100%;
                width: 100%;
                border-radius: 50%;
            }
        }
    }
}