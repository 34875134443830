.poll-ended {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;


    --poll-icon-size: 85px;
    --accent-color: black;

    .poll-icon-container {
        aspect-ratio: 1;
        position: relative;
        height: var(--poll-icon-size);
        width: var(--poll-icon-size);

        .avatar-container {
            padding: .375rem;

            .avatar {
                border-radius: 50%;
                background-color: var(--accent-color);
                padding: .5rem;
            }
        }
    }

    .poll-result-container {
        flex-grow: 1;
        margin: 0px 1rem;

        .poll-result-text-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            color: white;
            align-items: flex-end;
            margin-bottom: 0.3rem;

            .poll-result-state>h2 {
                margin: 0px;
                font-size: 44px;
                font-weight: 600;
                line-height: 67px;
                letter-spacing: 0em;
                text-align: left;

            }

            .poll-result {
                display: flex;
                align-items: center;

                >h2 {
                    margin: 0px;
                    font-size: 44px;
                    font-weight: 600;
                    line-height: 67px;
                    letter-spacing: 0em;
                    text-align: left;

                }

                .result {
                    margin-left: 1rem;
                    padding: .2rem .7rem;
                    border-radius: 4rem;
                }
            }
        }
    }
}