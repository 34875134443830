.slider-wrapper {
    width: inherit;
    display: inherit;
    flex-direction: inherit;
    align-items: inherit;
    height: inherit;
}

.slider-enter-active {
    animation: slideIn 1s ease-out;
}

.slider-exit-active {
    opacity: 0;
    scale: 0.9;
    transform: translateX(-50%);
    transition: opacity 300ms, transform 300ms, scale 300ms;
}


@keyframes slideIn {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}


@keyframes slideOut {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}