.scrolling-container {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  height: 100%;

  >div {
    position: absolute;
    min-height: 100%;
    min-width: 100%;
    display: flex;
    align-items: center;
  }

  .from-right {
    left: 100%;
  }

  .from-bottom {
    top: 100%;
  }

  .scrolling-element.horizontal {
    animation: scrollAnimationHorizontal var(--animation-duration) linear;
  }

  .scrolling-element.vertical {
    animation: scrollAnimationVertical var(--animation-duration) linear;
  }

}

@keyframes scrollAnimationHorizontal {
  0% {
    transform: translateX(0);
  }
  
  100% {
    transform: translateX(var(--animation-length));
  }
}

@keyframes scrollAnimationVertical {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(var(--animation-length));
  }
}